/* eslint-disable complexity */
import React from "react";
import { IconMenu } from "../../ui-components/IconMenu";
import { ExportDocumentAction } from "./document-actions/ExportDocumentAction";
import {
  EditorialNotesDocumentAction,
  useShouldShowEditorialNoteAction
} from "./document-actions/EditorialNotesDocumentAction";
import { AddToAssetCollectionAction } from "./document-actions/AddToAssetCollectionAction";
import { ToggleSubscriptionAction } from "./document-actions/ToggleSubscriptionAction";
import { FindInDocumentAction } from "./document-actions/FindInDocumentAction";
import { SendLinkAction } from "./document-actions/SendLinkAction";
import {
  ToggleAnnotationsAction,
  useShouldShowToggleAnnotationsAction
} from "./document-actions/ToggleAnnotationsAction";
import { useHideDocumentSubscriptionAlert } from "./document-actions/use-hide-document-subscription-alert";
import { useHideAddToAssetCollectionAction } from "./document-actions/use-hide-add-to-asset-collection";
import { useShouldShowSendLinkAction } from "./document-actions/use-should-show-send-link-action";
import ToggleCollapsibleSectionsAction from "./document-actions/ToggleCollapsibleSectionsAction";
import { useShouldShowFoldableSectionAction } from "../content/rhinestone-xml/document-components/FoldableSection";
import { Box, Hidden, makeStyles } from "@material-ui/core";
import ToggleLargeDocumentViewAction from "./document-actions/ToggleLargeDocumentViewAction";
import { useShouldShowToggleLargeDocumentViewAction } from "./document-actions/use-should-show-toggle-large-document-view-action";
import RelationsActionContainer from "./document-actions/relations-action/RelationsActionContainer";
import { useShouldShowExportDocumentAction } from "./document-actions/use-should-show-export-document-action";
import { useHideRelationsAction } from "./document-actions/use-hide-relations-action";
import LawZoomAction from "../law-zoom/LawZoomAction";
import { useShouldShowLawZoomAction } from "../law-zoom/use-should-show-law-zoom-action";
import FeatureFlag from "../../feature-flags/feature-flag/FeatureFlag";
import { DocumentFamilySubscriptionAction } from "./document-actions/DocumentFamilySubscriptionAction";
import { useShouldShowDocumentFamilySubscriptionAction } from "./document-actions/use-should-show-document-family-subscription-action";

export const iconMenuClassNames = { findToggle: "icon-menu-find-toggle" };

const useStyles = makeStyles(theme => ({
  floatingAction: {
    top: "6px",
    right: "6px",
    position: "absolute",
    height: "36px",
    width: "36px"
  }
}));

export const DocumentActionsMenu = () => {
  const classes = useStyles();
  return (
    <Box>
      <IconMenu size={"small"}>
        {useShouldShowLawZoomAction() && <LawZoomAction />}
        {!useHideRelationsAction() && <RelationsActionContainer />}
        <FindInDocumentAction />
        {useShouldShowExportDocumentAction() && <ExportDocumentAction />}
        {!useHideDocumentSubscriptionAlert() && <ToggleSubscriptionAction />}
        {useShouldShowEditorialNoteAction() && <EditorialNotesDocumentAction />}
        {!useHideAddToAssetCollectionAction() && <AddToAssetCollectionAction />}
        {useShouldShowSendLinkAction() && <SendLinkAction />}
        {useShouldShowToggleAnnotationsAction() && <ToggleAnnotationsAction />}
        {useShouldShowFoldableSectionAction() && (
          <ToggleCollapsibleSectionsAction />
        )}
        {useShouldShowDocumentFamilySubscriptionAction() && (
          <DocumentFamilySubscriptionAction />
        )}
      </IconMenu>
      <Hidden xsDown>
        {useShouldShowToggleLargeDocumentViewAction() && (
          <Box className={classes.floatingAction}>
            <ToggleLargeDocumentViewAction />
          </Box>
        )}
      </Hidden>
    </Box>
  );
};
