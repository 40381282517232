import { useState } from "react";
import { useLocation } from "react-router-dom";
import { PdfAnnotationModel } from "@rhinestone/portal-web-api";
import { useSearchParams } from "../../../../browser-utils/useSearchParams";
import { useRelativeApiBasePath } from "../../../api-access/PortalApiProvider";
import { ContextMenuPosition } from "../../../ui-components/ContextMenu/ContextMenu";
import { useAnnotationClientContext } from "../../annotations/AnnotationClientStateProvider";
import { useOrphanedAnnotations } from "../../annotations/OrphanedAnnotationsContainer";
import { usePdfAnnotationState } from "../../annotations/pdf/pdf-annotation-state";
import { useDocumentInfo } from "../../document-context";
import { useDocumentHighlightsContext } from "../../highlights/DocumentHighlightsProvider";
import { useFieldSet } from "../../use-fieldset";
import { getPageDestinationFromHash } from "./pdf-helper";
import { PdfSelection } from "./PdfViewer/annotations/pdf-selection";

export function usePdfContentContainerContext() {
  const { hiveId, fullName, annotationTarget } = useDocumentInfo();
  const basePath = useRelativeApiBasePath();
  const pdfDownloadUrl =
    `${basePath}` +
    `/Documents/Variant(Pdf)` +
    `/Content/h/${hiveId}/${fullName}`;

  const { title } = useFieldSet(hiveId, fullName) ?? {};

  const annotationState = usePdfAnnotationState(
    annotationTarget.annotationTargetId
  );
  const orphanedAnnotations = useOrphanedAnnotations(annotationState);

  const [isAnnotationsVisible] = useAnnotationClientContext();
  const highlights = useDocumentHighlightsContext();

  const { destination, ticketId } = useUrlParameters();

  return {
    pdfDownloadUrl,
    title,
    annotationState,
    orphanedAnnotations,
    isAnnotationsVisible,
    highlights,
    destination,
    ticketId
  };
}

export function usePdfContentContainerState() {
  const [selectedRect, setSelectedRect] = useState<PdfSelection>();
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<PdfAnnotationModel>();
  const [contextMenuPos, setContextMenuPos] = useState<ContextMenuPosition>();

  const onContextMenu = (
    mouseX: number,
    mouseY: number,
    selection?: PdfSelection,
    annotation?: PdfAnnotationModel
  ) => {
    setContextMenuPos({ mouseX, mouseY });
    setSelectedRect(selection);
    setSelectedAnnotation(annotation);
  };

  return { selectedRect, selectedAnnotation, contextMenuPos, onContextMenu };
}

function useUrlParameters() {
  const { hash } = useLocation();
  const destination = getPageDestinationFromHash(hash);
  const { ticketId } = useSearchParams() as { ticketId: string };
  return { destination, ticketId };
}
