import {
  Avatar,
  lighten,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles(theme => ({
  avatarPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatarSecondary: {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: "1px solid"
  }
}));

export interface ListSelectorItemProps {
  icon: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  variant?: "primary" | "default";
}
export const ListSelectorItem: React.FC<
  ListSelectorItemProps & Pick<ListItemProps, "disabled" | "onClick">
> = ({ icon, primary, secondary, variant, ...rest }) => {
  const classes = useStyles();

  return (
    <ListItem button {...rest}>
      {icon && (
        <ListItemAvatar>
          <Avatar
            className={
              variant === "primary"
                ? classes.avatarPrimary
                : classes.avatarSecondary
            }
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
};
