import { PdfNewAnnotationDTO } from "@rhinestone/portal-web-api";
import React from "react";
import { MenuItem } from "../../../ui-components/ContextMenu/ContextMenu";
import {
  Annotation,
  AnnotationContext
} from "../../annotations/annotation-state";
import { PdfAnnotationState } from "../../annotations/pdf/pdf-annotation-state";
import { PdfSelection } from "../../content/pdf/PdfViewer/annotations/pdf-selection";

export interface addPdfUserHighlightMenuItemCommonProps {
  annotationState: PdfAnnotationState;
  selection: PdfSelection | undefined;
  context: AnnotationContext | undefined;
}

interface addPdfUserHighlightMenuItemProps
  extends addPdfUserHighlightMenuItemCommonProps {
  labelText: React.ReactNode;
}

export function addPdfHighlightMenuItem(
  props: addPdfUserHighlightMenuItemProps
): MenuItem {
  return {
    onClick: () =>
      props.annotationState.addAnnotation({
        annotation: {
          comment: "",
          startX: props.selection?.StartX,
          startY: props.selection?.StartY,
          endX: props.selection?.EndX,
          endY: props.selection?.EndY,
          pageIndex: props.selection?.PageIndex
        } as PdfNewAnnotationDTO,
        contextId: props.context?.contextId
      } as Annotation<PdfNewAnnotationDTO>),
    label: <>{props.labelText}</>,
    hidden: () => !(props.selection && props.context)
  };
}
