/* eslint-disable complexity */
import {
  GeometricSelectionModel,
  PdfAnnotationModel
} from "@rhinestone/portal-web-api";
import React from "react";
import { Translate } from "react-localize-redux";
import { translationKeys } from "../../../gen/translation-keys";
import BasicContextMenu from "../../ui-components/ContextMenu/BasicContextMenu";
import {
  ContextMenuPosition,
  MenuItem
} from "../../ui-components/ContextMenu/ContextMenu";
import { Annotation } from "../annotations/annotation-state";
import AnnotationCommentEditorModal from "../annotations/comments/AnnotationCommentEditorModal";
import { DeleteAnnotationConfirmation } from "../annotations/DeleteAnnotationConfirmation";
import { PdfAnnotationState } from "../annotations/pdf/pdf-annotation-state";
import { AddToAssetCollectionContainer } from "../AssetCollection/AddToAssetCollectionContainer";
import { useHideAddToAssetCollectionAction } from "../DocumentActionMenu/document-actions/use-hide-add-to-asset-collection";
import { addPdfUserHighlightMenuItem } from "./menu-items/addPdfUserHighlightMenuItem";
import { addPdfAssetCollectionHighlightMenuItem } from "./menu-items/addPdfAssetCollectionHighlightMenuItem";
import { deleteAnnotationMenuItem } from "./menu-items/deleteAnnotationMenuItem";
import { addCommentMenuItem } from "./menu-items/addCommentMenuItem";
import { PdfSelection } from "../content/pdf/PdfViewer/annotations/pdf-selection";

interface PdfContextMenuProps {
  position?: ContextMenuPosition;
  pdfAnnotation?: PdfAnnotationModel;
  selection?: PdfSelection;
  annotationState: PdfAnnotationState;
}

export const PdfContextMenu: React.FC<PdfContextMenuProps> = ({
  children,
  position,
  pdfAnnotation,
  selection,
  annotationState
}) => {
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openEditor, setOpenEditor] = React.useState(false);
  const [openAssetCollections, setOpenAssetCollections] = React.useState(false);
  const hideAddToAssetCollection = useHideAddToAssetCollectionAction();

  const annotation = annotationState.annotations?.find(
    a => a.annotation.id === pdfAnnotation?.id
  );

  const menuItems: MenuItem[] = [];
  const userContext =
    annotationState.context?.type === "user"
      ? annotationState.context
      : undefined;
  const assetCollectionContext =
    annotationState.context?.type === "asset-collection"
      ? annotationState.context
      : undefined;

  menuItems.push(
    addPdfUserHighlightMenuItem({
      annotationState,
      selection,
      context: userContext
    })
  );

  menuItems.push(
    addPdfAssetCollectionHighlightMenuItem({
      annotationState,
      selection,
      context: assetCollectionContext
    })
  );

  menuItems.push({
    onClick: () => setOpenAssetCollections(true),
    label: <Translate id={translationKeys.ribbon.add_to_asset_collection} />,
    hidden: () => !selection || hideAddToAssetCollection
  });

  menuItems.push(
    deleteAnnotationMenuItem(annotation?.annotation, setOpenConfirmation)
  );

  menuItems.push(addCommentMenuItem(annotation?.annotation, setOpenEditor));

  const saveComment = async (
    annotation: Annotation<PdfAnnotationModel>,
    newComment: string
  ) => {
    await annotationState.addAnnotationComment({
      ...annotation,
      annotation: { ...annotation.annotation, comment: newComment }
    });
  };

  return (
    <React.Fragment>
      {children}
      <BasicContextMenu position={position} menuItems={menuItems} />
      {annotation && (
        <DeleteAnnotationConfirmation
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          removeAnnotation={annotationState.removeAnnotation}
          annotation={annotation}
        />
      )}
      {annotation && (
        <AnnotationCommentEditorModal
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          annotations={[annotation]}
          saveComment={saveComment}
        />
      )}
      {selection && openAssetCollections && (
        <AddToAssetCollectionContainer
          setOpen={setOpenAssetCollections}
          passage={
            {
              startX: selection.StartX,
              startY: selection.StartY,
              endX: selection.EndX,
              endY: selection.EndY,
              pageIndex: selection.PageIndex
            } as GeometricSelectionModel
          }
          isPdf={true}
        />
      )}
    </React.Fragment>
  );
};
